import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'

import Layout from '../components/Layout'
import Heading from '../components/Heading'
import Features from '../components/Features'
import BlogRoll from '../components/BlogRoll'
import Content, {HTMLContent} from "../components/Content";
import TourRoll from "../components/TourRoll";

export const IndexPageTemplate = ({
  image,
  title,
  subtitle,
  intro,
  content,
  contentComponent,
}) => {
  const PostContent = contentComponent || Content

  return (
      <div>
        <Heading title={title} subtitle={subtitle} image={image}/>
        <section className="section section--gradient">
          <div className="container">
            <div className="section">
              <div className="columns">
                <div className="column is-10 is-offset-1">
                  <div className="content">
                    <div className="content">
                      <div className="tile">
                        <h1 className="title">{intro.heading}</h1>
                      </div>
                      <div className="tile">
                        <h3 className="subtitle">{intro.description}</h3>
                      </div>
                    </div>
                    <Features gridItems={intro.blurbs} />
                    <PostContent content={content} />
                    <div className="columns">
                      <div className="column is-12 has-text-centered">
                        <Link className="btn" to="/training">
                          Búvároktatás
                        </Link>
                      </div>
                    </div>

                    <div className="column is-12">
                      <h3 className="has-text-weight-semibold is-size-2">
                        Legújabb túrák
                      </h3>
                      <TourRoll />
                      <div className="column is-12 has-text-centered">
                        <Link className="btn" to="/tours">
                          Tovább a túrákra
                        </Link>
                      </div>
                    </div>

                    <div className="column is-12">
                      <h3 className="has-text-weight-semibold is-size-2">
                        Legfrissebb hírek
                      </h3>
                      <BlogRoll />
                      <div className="column is-12 has-text-centered">
                        <Link className="btn" to="/blog">
                          Tovább a hírekre
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
  )
}

IndexPageTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  intro: PropTypes.shape({
    heading: PropTypes.string,
    description: PropTypes.string,
    blurbs: PropTypes.arrayOf({
      image: PropTypes.object,
      text: PropTypes.string
    })
  })
}

const IndexPage = ({ data }) => {
  const { html, frontmatter } = data.markdownRemark

  return (
    <Layout>
      <IndexPageTemplate
        title={frontmatter.title}
        subtitle={frontmatter.subtitle}
        image={frontmatter.image}
        description={frontmatter.description}
        intro={frontmatter.intro}
        content={html}
        contentComponent={HTMLContent}
      />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      html: PropTypes.node,
      frontmatter: PropTypes.shape({
        title: PropTypes.string,
        subtitle: PropTypes.string,
        image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
        intro: PropTypes.shape({
          heading: PropTypes.string,
          description: PropTypes.string,
          blurbs: PropTypes.arrayOf({
            image: PropTypes.object,
            text: PropTypes.string
          })
        })
      }),
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      html
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        subtitle
        intro {
          blurbs {
            image {
              childImageSharp {
                fluid(maxWidth: 240, quality: 64) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            text
          }
          heading
          description
        }
      }
    }
  }
`
